import type { InjectionKey } from 'vue'

export interface IFormField {
  readonly invalid: boolean
  updateValidation(): void
  resetValidation(): void
}

export interface IFormAction {
  readonly loading: boolean

  submit?: undefined
}

export interface IFormSubmitAction {
  readonly loading: boolean
  submit(): unknown | Promise<unknown>
}

export interface IForm {
  registerField(field: IFormField): void
  unregisterField(field: IFormField): void

  registerAction(action: IFormAction | IFormSubmitAction): void
  unregisterAction(action: IFormAction | IFormSubmitAction): void
  readonly invalid: boolean
  readonly loading: boolean

  setErrorMessage(errorMessage: string): void
  clearErrorMessage(): void
}

export const formInjectionKey = Symbol('nuag-form') as InjectionKey<IForm>
