import { NuxtAppCompat } from '@nuxt/bridge/dist/runtime'

import Dropdown from 'primevue/dropdown'
import button from './base/button.vue'
import SplitButton from './base/split-button.vue'
import emoji from './base/emoji.vue'
import illustration from './base/illustration.vue'
import icon from './base/icon.vue'
import message from './base/message.vue'
import tag from './base/tag.vue'

import labelledDoughnut from './charts/labelled-doughnut.vue'

import form from './form/form.vue'
import checkbox from './form/checkbox.vue'
import input from './form/input.vue'
import inputMask from './form/input-mask.vue'
import radio from './form/radio.vue'
import rule from './form/rule.vue'
import submit from './form/submit.vue'
import editor from './form/editor.vue'
import autosave from './form/autosave.vue'
import calendar from './form/calendar.vue'

import backlink from './navigation/backlink.vue'
import selectionList from './navigation/selection-list.vue'

import sidebar from './layouts/sidebar.vue'
import sidebarLink from './layouts/sidebar-link.vue'

import splitPageLayout from './layouts/page/split.vue'

import projectTracker from './project/tracker.vue'
import projectTask from './project/task.vue'
import projectSubtask from './project/subtask.vue'

import autolink from './autolink.vue'
import banner from './banner.vue'
import infoPanel from './info-panel.vue'
import notificationArea from './notification-area.vue'
import breadcrumbNavigation from './breadcrumb-navigation.vue'
import frame from './frame.vue'
import progressBar from './progress-bar.vue'
import progressCircular from './progress-circular.vue'
import table from './table.vue'
import waffleSpinner from './waffle-spinner.vue'

import usePrimeVue from './primevue'

export { notify } from './notifications'

export default function useNuagDesignComponents (nuxtApp: NuxtAppCompat) {
  usePrimeVue(nuxtApp)

  nuxtApp.vueApp.component('nuag-button', button)
  nuxtApp.vueApp.component('nuag-split-button', SplitButton)
  nuxtApp.vueApp.component('nuag-emoji', emoji)
  nuxtApp.vueApp.component('nuag-illustration', illustration)
  nuxtApp.vueApp.component('nuag-icon', icon)
  nuxtApp.vueApp.component('nuag-message', message)
  nuxtApp.vueApp.component('nuag-tag', tag)

  nuxtApp.vueApp.component('nuag-form', form)
  nuxtApp.vueApp.component('nuag-form-editor', editor)
  nuxtApp.vueApp.component('nuag-form-input', input)
  nuxtApp.vueApp.component('nuag-form-input-mask', inputMask)
  nuxtApp.vueApp.component('nuag-form-radio', radio)
  nuxtApp.vueApp.component('nuag-form-rule', rule)
  nuxtApp.vueApp.component('nuag-form-checkbox', checkbox)
  nuxtApp.vueApp.component('nuag-form-submit', submit)
  nuxtApp.vueApp.component('nuag-form-autosave', autosave)
  nuxtApp.vueApp.component('nuag-form-dropdown', Dropdown)
  nuxtApp.vueApp.component('nuag-form-calendar', calendar)

  nuxtApp.vueApp.component('nuag-backlink', backlink)
  nuxtApp.vueApp.component('nuag-selection-list', selectionList)

  nuxtApp.vueApp.component('nuag-sidebar', sidebar)
  nuxtApp.vueApp.component('nuag-sidebar-link', sidebarLink)

  nuxtApp.vueApp.component('nuag-page-layout-split', splitPageLayout)

  nuxtApp.vueApp.component('nuag-project-tracker', projectTracker)
  nuxtApp.vueApp.component('nuag-project-task', projectTask)
  nuxtApp.vueApp.component('nuag-project-subtask', projectSubtask)

  nuxtApp.vueApp.component('nuag-autolink', autolink)
  nuxtApp.vueApp.component('nuag-banner', banner)
  nuxtApp.vueApp.component('nuag-info-panel', infoPanel)
  nuxtApp.vueApp.component('nuag-notification-area', notificationArea)
  nuxtApp.vueApp.component('nuag-breadcrumb-navigation', breadcrumbNavigation)
  nuxtApp.vueApp.component('nuag-frame', frame)
  nuxtApp.vueApp.component('nuag-progress-bar', progressBar)
  nuxtApp.vueApp.component('nuag-progress-circular', progressCircular)
  nuxtApp.vueApp.component('nuag-table', table)
  nuxtApp.vueApp.component('nuag-waffle-spinner', waffleSpinner)

  nuxtApp.vueApp.component('nuag-labelled-doughnut', labelledDoughnut)
}
