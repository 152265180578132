import { BlockBlobClient } from '@azure/storage-blob'

export function downloadBlob (blob: Blob, fileName: string, fileType: string): void {
  downloadUrl(window.URL.createObjectURL(blob), fileName, fileType, false)
}

export function downloadUrl (url: string, fileName: string, fileType: string, newTab: boolean): void {
  const a = document.createElement('a')

  a.style.display = 'none'
  a.href = url
  a.download = fileName
  a.type = fileType
  if (newTab) { a.target = '_blank' }

  document.body.appendChild(a)
  a.click()

  setTimeout(() => {
    window.URL.revokeObjectURL(url)
    a.remove()
  }, 0)
}

export async function uploadFile (file: File, signedUrl: string): Promise<void> {
  const blobClient = new BlockBlobClient(signedUrl)
  await blobClient.uploadBrowserData(file)
}

export async function uploadBlob (blob: Blob, signedUrl: string): Promise<void> {
  const blobClient = new BlockBlobClient(signedUrl)
  await blobClient.uploadData(blob)
}

export function cdnFileUrl (fileName: string): string {
  let cdn = process.env.PUBLIC_CDN!
  if (!cdn.endsWith('/')) {
    cdn += '/'
  }
  return cdn + fileName
}

export function fileExtension (fileName: string): string {
  const dotIndex = fileName.lastIndexOf('.')
  if (dotIndex < 0) { return '' }
  return fileName.substr(dotIndex + 1)
}

const MIME_TABLE: Record<string, string | undefined> = {
  doc: 'application/msword',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  pdf: 'application/pdf',
  png: 'image/png',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.ms-powerpoint',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  csv: 'text/csv',
  zip: 'application/zip'
}

export function documentColor (iconName: string): string {
  switch (iconName) {
    case 'file-pdf': return '#b71c1c'
    case 'file-image': return '#1e88e5'
    case 'file-word': return '#0d47a1'
    case 'file-powerpoint': return '#e65100'
    case 'file-excel': return '#1b5e20'
    case 'file-archive': return '#f9a825'
  }
  return '#4e259f'
}

export function fileMimeFromFileName (fileName: string): string {
  const extension = fileExtension(fileName)
  return MIME_TABLE[extension.toLowerCase()] || 'application/octet-stream'
}

export function documentIcon (mimeType: string): string {
  if (mimeType.startsWith('image/')) { return 'file-image' }
  if (mimeType.startsWith('audio/')) { return 'file-audio' }
  if (mimeType.startsWith('video/')) { return 'file-video' }

  switch (mimeType) {
    case 'application/zip': return 'file-archive'
    case 'application/pdf': return 'file-pdf'
    case 'application/msword': return 'file-word'
    // csv, xls & xlsx
    case 'text/csv':
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': return 'file-excel'
    // I hate you powerpoint
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': return 'file-powerpoint'
    default: return 'file'
  }
}

export function sizeInHumanReadableMb (size: number): string {
  return (size / 1_000_000).toFixed(2)
}
